<template>
  <section class="content">
    <div class="account-element flex">
      <div class="left-content" :class="{ h500: showSelect }">
        <a-tree
          :tree-data="treeList"
          :selectedKeys.sync="selectedKeys"
          :expandedKeys="expandedKeys"
          @expand="onExpand"
          @select="onSelect"
        />
      </div>
      <div class="right-content">
        <div class="form-content pl20">
          <a-row :gutter="16" style="margin-top: 15px">
            <a-col :span="6">
              <a-input
                allow-clear
                v-model.trim="nameOrCode"
                :placeholder="$t('搜索编码/名称')"
                @keydown.enter="getTableList(1)"
              />
            </a-col>
            <a-col :span="3">
              <a-button @click="getTableList(1)" type="primary">
                <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
                {{ $t(`查询`) }}
              </a-button>
            </a-col>
            <a-col :span="15">
              <Pagination
                :pageNo="pageNo"
                :length="pageSize"
                :total-count="total"
                @paginate="paginate"
              />
            </a-col>
          </a-row>
        </div>
        <div class="table-content">
          <div class="table-body">
            <CommonTable
              :showSelect="showSelect"
              :columns.sync="columns"
              :data-source="tableList"
              :scroll="{ y: scrollHeight }"
              :loading="loading"
              :showPagination="false"
              :selectedRowKeys.sync="selectedRowKeys"
              :selectedRows.sync="selectedRows"
              rowKey="code"
              @select="onTableSelect"
            >
              <template v-slot:code="{ text, record }">
                <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
              </template>
            </CommonTable>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import { buildTree } from '@/common'
import Pagination from '@/component/pagination'
export default {
  name: 'accountList',
  components: { Pagination },
  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    hasSelected: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getTreeList()
    this.getTableList()
  },
  data() {
    return {
      treeLoading: false,
      loading: false,
      treeList: [],
      tableList: [],
      rootCode: undefined,
      selectedKeys: ['all'],
      expandedKeys: [],
      visible: false,
      form: {
        code: '',
        name: '',
        balance_direction: '',
      },
      rules: {
        code: [{ required: true, message: this.$t('请输入编码'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('请输入名称'), trigger: 'blur' }],
        balance_direction: [
          { required: true, message: this.$t('请选择余额方向'), trigger: 'change' },
        ],
      },
      pageNo: 1,
      pageSize: 20,
      total: 1,
      selectedRowKeys: [],
      selectedRows: [],
      nameOrCode: undefined,
      selectData: {},
    }
  },
  watch: {
    hasSelected(val) {
      if (val.length > 0) {
        this.selectedRows = val
        this.selectedRowKeys = val.map((item) => item.code)
      } else {
        this.selectedRows = []
        this.selectedRowKeys = []
      }
    },
  },
  computed: {
    ...mapGetters(['balanceDirectionMapping']),
    scrollHeight() {
      return this.showSelect ? 500 : window.innerHeight - 300
    },
    columns() {
      return [
        {
          title: this.$t('编码'),
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: this.$t('名称'),
          dataIndex: 'full_name',
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimension_name',
          customRender: (text, record) => {
            if (record.dimension_list.length > 0) {
              return record.dimension_list.map((item) => item.name).join(';')
            }
            return ''
          },
        },
        {
          title: this.$t('会计要素表'),
          dataIndex: 'root_name',
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'balance_direction',
          customRender: (text) => this.balanceDirectionMapping[text],
        },
        {
          title: this.$t('上级会计要素'),
          dataIndex: 'parent_name',
        },
        {
          title: this.$t('系统预置'),
          dataIndex: 'is_preset',
          customRender: (text) => {
            return text ? this.$t('是') : this.$t('否')
          },
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
        },
      ]
    },
  },
  methods: {
    getTreeList() {
      this.treeLoading = true
      http({
        url: api.getAccountElementist,
        type: 'post',
        success: (res) => {
          this.treeLoading = false
          const tree = buildTree(res.result.list)
          this.treeList = [
            {
              title: this.$t('全部'),
              key: 'all',
              children: tree,
            },
          ]
          this.expandedKeys = ['all']
        },
      })
    },
    getTableList(isFirst = false) {
      if (isFirst) {
        this.pageNo = 1
      }
      this.loading = true
      http({
        url: api.getAccountList,
        type: 'post',
        data: {
          page_no: this.pageNo,
          page_size: this.pageSize,
          nameOrCode: this.nameOrCode,
          ...this.selectData,
        },
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list
          this.total = res.result.total
        },
      })
    },
    onSelect(selectedKeys) {
      this.pageNo = 1
      const data = {}
      if (selectedKeys.length) {
        if (selectedKeys[0] !== 'all') {
          let arr = selectedKeys[0].split('-')
          if (arr.length === 1) {
            let selectItem = this.treeList[0].children.find((elem) => elem.code === arr[0])
            data.elementCodeList =
              selectItem.childrenCodeList?.length > 0 ? selectItem.childrenCodeList : arr
          } else {
            data.elementCodeList = [arr[1]]
          }
        }
        this.selectData = data
        this.getTableList()
      }
    },
    viewDetail(record) {
      if (this.showSelect) {
        this.$emit('change', record)
        return
      }
      this.$router.push({
        name: 'accountDetail',
        query: { id: record.id },
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getTableList()
    },
    onTableSelect(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.$emit('select', selectedRows)
    },
  },
}
</script>

<style scoped lang="scss">
.left-content {
  min-width: 200px;
  width: 250px;
  border-right: 1px solid #e8e8e8;
  height: calc(100vh - 180px);
  &.h500 {
    height: 500px;
  }
}
.table-content {
  flex: 1;
}
</style>
