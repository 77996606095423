<template>
  <div class="content ledger-list">
    <div class="form-content">
      <a-row :gutter="16">
        <a-col :span="6">
          <SelectAccountSet
            :placeholder="$t(`账簿`)"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="6">
          <a-input
            readOnly
            :value="accountName"
            @click="showAccountModal"
            :placeholder="$t('科目名称')"
          />
        </a-col>
        <a-col :span="6">
          <a-select
            style="width: 100%"
            v-model="accountingPeriodStart"
            :placeholder="$t(`开始期间`)"
          >
            <a-select-option
              v-for="(item, index) in periodListStart"
              :key="index"
              :value="item.accounting_period_code"
            >
              {{ item.accounting_period_name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-select style="width: 100%" v-model="accountingPeriodEnd" :placeholder="$t(`结束期间`)">
            <a-select-option
              v-for="(item, index) in periodListEnd"
              :key="index"
              :value="item.accounting_period_code"
            >
              {{ item.accounting_period_name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :scroll="{ x: 1500, y: scrollHeight }"
      :total="total"
      rowKey="id"
      @search="getList"
    >
      <template v-slot:dimensionList="{ record }">
        <div
          class="text-link"
          v-for="(item, index) in record.dimensionMappingList"
          :key="index"
          @click="goToDimensionList(item, record)"
        >
          {{ item.accountingDimensionName }}: {{ item.accountingDimensionValueName }}
        </div>
      </template>
      <template v-slot:debitAmount="{ record }">
        <PriceInput :value.sync="record.debitAmount" :showTxt="true" :currency="record.currency" />
      </template>
      <template v-slot:creditAmount="{ record }">
        <PriceInput :value.sync="record.creditAmount" :showTxt="true" :currency="record.currency" />
      </template>
      <template v-slot:voucherNo="{ text, record }">
        <span class="text-link" @click="goJump(record)">
          {{ $store.getters.journalVoucherTypeMapping[record.voucherType] }} {{ text }}
        </span>
      </template>
    </CommonTable>
    <SelectAccountModal ref="SelectAccountModalRef" :hasSelected="selectedRows" @ok="onOk" />
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountModal from '@/component/selectAccountModal'
import SelectAccountSet from '@/component/selectAccountSet'
import { convertKeysToCamelCase, jumpSoucePage } from '@/common'
export default {
  name: 'accountJournalList',
  components: { SelectAccountModal, SelectAccountSet },
  data() {
    return {
      docType: 'Ledger',
      formData: {
        accountSetUnionCode: undefined,
        accountCodeList: [],
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],
      periodList: [],

      accountingPeriodStart: undefined,
      accountingPeriodEnd: undefined,
      accountName: undefined,
      visible: false,
    }
  },
  mounted() {
    this.setColumns()
    this.getPeriodList()
  },
  activated() {
    let query = localStorage.getItem('accountJournalInfo')
    if (query) {
      query = JSON.parse(query)
      this.accountName = query.accountName
      this.formData.accountCodeList = [query.accountCode]
      this.formData.businessUnitCode = query.businessUnitCode
      this.formData.accountSetCode = query.accountSetCode
      this.formData.accountSetUnionCode = query.accountSetCode + '-' + query.businessUnitCode
      this.selectedRows = [
        {
          code: query.accountCode,
          name: query.accountName,
        },
      ]
      if (query.accountingPeriodStart) {
        this.accountingPeriodStart = query.accountingPeriodStart
        this.accountingPeriodEnd = query.accountingPeriodEnd
      }
      this.searchHandle()
      localStorage.removeItem('accountJournalInfo')
    }
  },
  computed: {
    scrollHeight() {
      return window.innerHeight - 350
    },
    periodListStart() {
      let list = this.periodList
      if (this.accountingPeriodEnd) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isBefore(this.accountingPeriodEnd) ||
            item.accounting_period_code === this.accountingPeriodEnd
        )
      }
      return list
    },
    periodListEnd() {
      let list = this.periodList
      if (this.accountingPeriodStart) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isAfter(this.accountingPeriodStart) ||
            item.accounting_period_code === this.accountingPeriodStart
        )
      }
      return list
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('科目编码'),
          dataIndex: 'accountCode',
        },
        {
          title: this.$t('科目名称'),
          dataIndex: 'accountName',
        },
        {
          title: this.$t('会计期间'),
          dataIndex: 'accountingPeriodName',
        },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accountingDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('凭证字号'),
          dataIndex: 'voucherNo',
          scopedSlots: { customRender: 'voucherNo' },
        },
        {
          title: this.$t('摘要'),
          dataIndex: 'summary',
          width: 200,
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionList',
          width: 250,
          scopedSlots: { customRender: 'dimensionList' },
        },
        {
          title: this.$t('借方金额'),
          dataIndex: 'debitAmount',
          scopedSlots: {
            customRender: 'debitAmount',
          },
          width: 120,
        },
        {
          title: this.$t('贷方金额'),
          dataIndex: 'creditAmount',
          scopedSlots: {
            customRender: 'creditAmount',
          },
          width: 120,
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'balanceDirection',
          width: 80,
          customRender: (text) => this.$store.getters.balanceDirectionMapping[text],
        },
        {
          title: this.$t('余额'),
          dataIndex: 'balance',
          customRender: (text, record) => this.$currencySymbol(text, record.currency),
          width: 150,
        },
      ]
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
      this.searchHandle(1)
    },
    getPeriodList() {
      http({
        url: api.queryAccountingCalendarDetailPage,
        data: {},
        success: (res) => {
          this.periodList = res.result.list
        },
      })
    },
    generatePeriods(startDate, endDate) {
      const periods = []
      let currentDate = new Date(startDate)
      const endDateObj = new Date(endDate)
      while (currentDate <= endDateObj) {
        periods.push(currentDate.toISOString().slice(0, 7)) // 格式化为 YYYY-MM
        currentDate.setMonth(currentDate.getMonth() + 1) // 增加一个月
      }
      return periods
    },
    async getList(params) {
      this.loading = true
      const data = {
        ...this.formData,
        ...params,
      }
      if (this.accountingPeriodStart && this.accountingPeriodEnd) {
        data.accountingPeriodList = this.generatePeriods(
          this.accountingPeriodStart,
          this.accountingPeriodEnd
        )
      }
      await http({
        url: api.getAccountJournalList,
        data,
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list.map((item) => {
            if (item.direction === 'CREDIT') {
              item.creditAmount = item.amount
            } else {
              item.debitAmount = item.amount
            }
            item.accountingPeriodName =
              item.accountingPeriod.replace('-', this.$t('年')) + this.$t('期')
            return item
          })
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    goJump(record) {
      jumpSoucePage(record.voucherSn, record.voucherId)
    },
    showAccountModal() {
      this.$refs.SelectAccountModalRef.open()
    },
    onOk(list) {
      this.selectedRows = list
      this.formData.accountCodeList = list.map((item) => item.code)
      this.accountName = list.map((item) => item.name).join(';')
      this.searchHandle(1)
    },
    goToDimensionList(item, record) {
      const data = {
        accountSetCode: record.accountSetCode,
        businessUnitCode: record.businessUnitCode,
        accountCode: record.accountCode,
        accountName: record.accountName,
        sourceTable: item.accountingDimensionSourceTable,
        accountingDimensionCode: item.accountingDimensionCode,
        accountingDimensionName: item.accountingDimensionName,
        accountingDimensionValueCode: item.accountingDimensionValueCode,
        accountingDimensionValueName: item.accountingDimensionValueName,
      }
      localStorage.setItem('accountJournalDimensionInfo', JSON.stringify(data))
      this.$router.push({
        name: 'accountJournalDimensionList',
      })
    },
  },
}
</script>
